import React, {useState} from 'react';
import { graphql } from 'gatsby';
import TitlePage from '../components/TitlePage';
import SEO from '../components/seo';
import Container from '../components/Container'
import RichText from '../components/RichText'
import * as S from './pricing-page.styled';
import PricingTable from '../components/PricingTable'
import PricingCards from '../components/PricingCards'

const PricingPage = props => {
  const locale = props.data.markdownRemark.fields.locale;
  const pageContent = props.data.markdownRemark;

  return (
    <>
      <SEO
        lang={locale}
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
        image={pageContent.frontmatter.image}
      />

    <S.Section>
      <Container>
        <TitlePage text={pageContent.frontmatter.title} />
            <S.PageIntro>{pageContent.frontmatter.description}</S.PageIntro>
            {
                pageContent.html == null || pageContent.html === "" ? null : 
                <RichText dangerouslySetInnerHTML={{ __html: pageContent.html }} />
            }        
      </Container>
    </S.Section>
  
    <S.SectionAlt>
      <Container>
         <PricingCards content={props.data.markdownRemark.frontmatter.pricingCards}></PricingCards>
      </Container>
    </S.SectionAlt>

    <S.Section id="pricing-table">
      <Container>
        <PricingTable content={props.data.markdownRemark.frontmatter.pricingTable}></PricingTable>                
      </Container>
    </S.Section>

    </>
  );
};

export const query = graphql`
  query PricingPage($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      fields {
        locale
      }       
      frontmatter {
        title
        description
        image
        pricingCards {
          monthly
          yearly
          compare
          help
          phone
          email
          items {
            isHighlighted
            title
            priceMonth
            priceYear
            priceText
            bullet1
            bullet2
            bullet3
            bullet4
            plusText
          }
        }
        pricingTable {
          title
          notes
          modules {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }

          reports {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }   

          dataImport {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }   

          dataExport {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }             

          documentExport {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }       
        
          branding {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }           

          templateStore {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          } 

          draftSharing {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          } 

          integrationOptions {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }           

          users {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          } 
         
          support {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }  
          
          other {
            title
            items {
              title
              basic
              professional
              premium
              custom
            }
          }           

        }
      }
      html
    }
  }
`;

export default PricingPage;

import styled from 'styled-components';

export const PricingWrapper = styled.div`
  h2{
    text-align: center;
  }
`;

export const Scrollable = styled.div`
  overflow: auto;
`;

export const PricingNotes = styled.p`
  margin-top: var(--space-lg);
  font-size: 16px;
  font-style: italic;
`;

export const PricingTable = styled.table`
  width: 100%;
  min-width: 650px;
  margin-top: var(--space-lg);
  tr{
    border-bottom: 1px solid var(--gray-light);
  }
  tr:nth-child(2n){
    background: var(--gray-extra-light);
  }
  td{
    padding: 2px var(--space-sm);
    font-size: 14px;
    vertical-align: middle;
  }
  th {
    font-weight: 600;
    background-color: var(--secondary-color);
    text-align: left;
    color: var(--color-white);
    padding: var(--space-sm);
  }
  img {
    width: 12px;
    &.icon-delete{
      fill: red;
    }
  }
`;

export const PricingSection = styled.p`
  padding-top: var(--space);
  font-weight: 600;
  font-size: 16px;
`;

import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';


export const PricingCards = styled.div`
  h2 {
    text-align: center;
  }
  &.displayYearly{
    .displayYearly {
      display: block;
    }
    .displayMonthly {
      display: none;
    }    
  }
  &.displayMonthly{
    .displayYearly {
      display: none;
    }
    .displayMonthly {
      display: block;
    }  
  }
`;

export const Tabs = styled.div`
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: var(--color-white);
  max-width: 320px;
  height: 56px;
  border-radius: 28px;
  padding: 6px;
  align-items: center;
  margin-bottom: 2rem;
  button{
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 44px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    background-color: white;
    cursor: pointer;
  }
  button.active{
    background: var(--secondary-color);
    color: white;    
  }
`;

export const Cards = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: var(--space-xl);
`;

export const Card = styled.div`
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  margin: 1rem;
  padding: var(--space) var(--space);
  border-radius: 20px;
  border: 5px solid var(--color-white);
  background-color: var(--color-white);
  width: calc(100% - 2rem);
  text-align: center;
  ${media.greaterThan('medium')`
    width: calc(50% - 2rem);
  `} 
  ${media.greaterThan('large')`
    width: calc(25% - 2rem);
  `} 
`;

export const CardAlt = styled.div`
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  margin: 1rem;
  padding: var(--space) var(--space);
  border-radius: 20px;
  border: 5px solid var(--primary-color);
  background-color: var(--color-white);
  width: calc(100% - 2rem);
  text-align: center;
  ${media.greaterThan('medium')`
    width: calc(50% - 2rem);
  `} 
  ${media.greaterThan('large')`
    width: calc(25% - 2rem);
  `} 
`;

export const CardContent = styled.div`
  h2{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
  }

  .price{
    font-size: 32px;
    height: 44px;
    margin-top: 2rem;
    font-weight: 600;
    text-align: center;
    img{
      height: 32px;
      margin-left: auto;
      margin-right: auto;
      display:block;
    }

  }
  .priceText{
    font-size: 14px;
    font-style: italic;
  }

  ul{
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--gray-light);
    font-size: 14px;
    text-align: left;
    list-style-type: disc;
    li{
      margin-left: 2rem;
    }
  }

  .plusText{
    background-color: var(--gray-extra-light);
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 1rem 0;
    font-size: 14px;
    font-weight: 600;
  }
  .compare{
    margin-bottom: 2rem;
    font-size: 14px;
    display: block;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

  }
`;

export const Link = styled(LocalizedLink)`
  margin-top: 1rem;
  display: block;
  text-decoration: none;
  color: var(--action-color);
  font-size: 16px;
  &:hover{
    color: var(--action-color-hover);
    text-decoration: underline;
  }
`;

export const PrimaryButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--action-color);
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  font-size: 16px;
  &:hover{
    color: var(--color-white);
    background-color: var(--action-color-hover);
  }  
`;

export const SecondaryButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  border: 1px solid var(--action-color);
  color: var(--action-color);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  transition-property: background-color, border-color;
  font-size: 16px;
  &:hover{
    border-color: var(--action-color);
    color: var(--color-white);
    background-color: var(--action-color);
  }  
`;

export const HelpButtons = styled.div`
  display: flex;
  justify-content: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    padding: 0 2.5rem;
    height: 44px;
    border-radius: 22px;
    border: 1px solid var(--action-color);
    color: var(--action-color);
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s ease-in-out;
    transition-property: background-color, border-color;
    font-size: 16px;
    svg {
      height: 22px;
      margin-right: 5px;
      fill: var(--action-color);
    }    
    &:hover{
      color: var(--color-white);
      border-color: var(--action-color);
      background-color: var(--action-color);
      svg {
        fill: var(--color-white);
      }         
    }     
  }
  
  ${media.lessThan('medium')`
    flex-wrap: wrap;
    a {
      width: 100%;
    }
  `} 

`;
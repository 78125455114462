import React from 'react';
import SubTitlePage from '../../components/SubTitlePage'
import { useStaticQuery, graphql } from 'gatsby';
import * as S from './styled';

const PricingTable = props => {
  const { content } = props;

  const { iconDelete, iconCheckmark } = useStaticQuery(
    graphql`
    query {
      iconDelete: allFile(filter: {name: {eq: "icon-delete"}}) {
        edges {
          node {
            publicURL
          }
        }
      }  
      iconCheckmark: allFile(filter: {name: {eq: "icon-checkmark"}}) {
        edges {
          node {
            publicURL
          }
        }
      }                   
    }
    `,
  );

  function valueHelper(text){
    let value;
    switch(text){
      case "X":
        value = <img src={iconDelete.edges[0].node.publicURL} alt={text} className="icon-delete"></img>
        break;
      case "V":
        value = <img src={iconCheckmark.edges[0].node.publicURL} alt={text} className="icon-checkmark"></img>
        break;
      default:
        value = text;
    }
    return value;
  }

  return (
  
  <S.PricingWrapper>

    <SubTitlePage text={content.title} />
    <S.Scrollable>
          <S.PricingTable>
            <thead>
              <tr>
                <th></th>
                <th>Basic</th>
                <th>Professional</th>
                <th>Premium</th>
                <th>Custom</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.modules.title}                  
                  </S.PricingSection>
                </td>
              </tr>            

              {
                content.modules.items.map(
                  ({
                  title,
                  basic,
                  professional,
                  premium,
                  custom
                }, index) => (
                  <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                ),
                )}

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.reports.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.reports.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.dataImport.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.dataImport.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}              

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.dataExport.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.dataExport.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}   


              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.documentExport.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.documentExport.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}                


              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.branding.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.branding.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}  

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.templateStore.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.templateStore.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}  

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.draftSharing.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.draftSharing.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.integrationOptions.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.integrationOptions.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.users.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.users.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.support.title}                  
                  </S.PricingSection>

                </td>
              </tr>            

              {
                content.support.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}              

              <tr>
                <td colSpan="5">
                  <S.PricingSection>
                    {content.other.title}                  
                  </S.PricingSection>
                </td>
              </tr>            

              {
                content.other.items.map(
                  ({
                    title,
                    basic,
                    professional,
                    premium,
                    custom
                  }, index) => (
                    <tr key={index}><td>{title}</td><td>{valueHelper(basic)}</td><td>{valueHelper(professional)}</td><td>{valueHelper(premium)}</td><td>{valueHelper(custom)}</td></tr>
                  ),
                )}

            </tbody>
          </S.PricingTable>
        </S.Scrollable>                    
      
      <S.PricingNotes dangerouslySetInnerHTML={{ __html: content.notes}}/> 


  </S.PricingWrapper>

  );
};

export default PricingTable;

import React, {useState} from 'react';
import SubTitlePage from '../../components/SubTitlePage'
import { useStaticQuery, graphql } from 'gatsby';
import TryNowButton from '../TryNowButton';

import * as S from './styled';

const PricingCards = props => {
  const { content } = props;
  const [yearly, setYearly] = useState(true);

  const { iconCustom } = useStaticQuery(
    graphql`
    query {
      iconCustom: allFile(filter: {name: {eq: "icon-custom"}}) {
        edges {
          node {
            publicURL
          }
        }
      }             
    }
    `,
  );    

  const iconCustomUrl = iconCustom.edges[0].node.publicURL
  
  return (
    <S.PricingCards className={yearly ? 'displayYearly' : 'displayMonthly'}>

    <S.Tabs>
        <button className={!yearly ? 'active' : null} onClick={() => setYearly(false)} role="button" tabIndex="0">
          {content.monthly}
        </button>
        <button className={yearly ? 'active' : null} onClick={() => setYearly(true)} role="button" tabIndex="0">
          {content.yearly}
        </button>
    </S.Tabs>

    <S.Cards>

     {
      content.items.map(
        ({
        title,
        isHighlighted,
        priceYear,
        priceMonth,
        priceText,
        bullet1,
        bullet2,
        bullet3,
        bullet4,
        plusText,
        link,
        linkUrl
      }, index) => (

        isHighlighted ? 

        <S.CardAlt key={index}>
          <S.CardContent>
            <h2>{title}</h2>

            <p className="price displayYearly">
              { priceYear ? priceYear : <img src={iconCustomUrl} alt="title" /> }            
            </p>

            <p className="price displayMonthly">
              { priceMonth ? priceMonth : <img src={iconCustomUrl} alt="title" /> }   
            </p>

            <p className="priceText">{priceText}</p>

            <ul>
              <li>{bullet1}</li>
              <li>{bullet2}</li>
              <li>{bullet3}</li>
              <li>{bullet4}</li>
            </ul>

            <p className="plusText">{plusText}</p>

            <a className="compare" href="#pricing-table">{content.compare}</a>

          </S.CardContent>
          
           <div style={{display: `flex`, justifyContent: `center`}}>
              <TryNowButton lang="nl" />
            </div>
            <S.Link href={linkUrl}>{link}</S.Link>
           
        </S.CardAlt>        
        
        : 
        
        <S.Card key={index}>
          <S.CardContent>
            <h2>{title}</h2>
            
            <p className="price displayYearly">
              { priceYear ? priceYear : <img src={iconCustomUrl} alt="title" /> }            
            </p>

            <p className="price displayMonthly">
              { priceMonth ? priceMonth : <img src={iconCustomUrl} alt="title" /> }   
            </p>

            <p className="priceText">{priceText}</p>

            <ul>
              <li>{bullet1}</li>
              <li>{bullet2}</li>
              <li>{bullet3}</li>
              <li>{bullet4}</li>
            </ul>

            <p className="plusText">{plusText}</p>

            <a className="compare" href="#pricing-table">{content.compare}</a>
        
          </S.CardContent>

          <div style={{display: `flex`, justifyContent: `center`}}>
              <TryNowButton lang="nl" secondary={true}/>
            </div>


            <S.Link href={linkUrl}>{link}</S.Link> 
           
        </S.Card>


      ),
      )}     

    </S.Cards>


    
    <SubTitlePage text={content.help} />

    <S.HelpButtons>
        <a href={"tel:"+content.phone.replace(/\D/g,'')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">    <path d="M21.225,17.889c-0.406-0.238-0.905-0.233-1.309,0.007l-2.046,1.219c-0.458,0.273-1.03,0.241-1.45-0.087 c-0.726-0.567-1.895-1.521-2.912-2.538c-1.017-1.017-1.971-2.186-2.538-2.912c-0.328-0.42-0.36-0.992-0.087-1.45l1.219-2.046 c0.241-0.404,0.243-0.907,0.005-1.313L9.105,3.641c-0.291-0.496-0.869-0.74-1.428-0.603C7.134,3.17,6.429,3.492,5.69,4.232 c-2.314,2.314-3.543,6.217,5.159,14.919s12.604,7.474,14.919,5.159c0.741-0.741,1.062-1.447,1.195-1.991 c0.135-0.558-0.105-1.132-0.6-1.422C25.127,20.174,22.461,18.613,21.225,17.889z"></path></svg>
          {content.phone}
        </a>
        {/* <a href={"mailto:"+content.email}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">    <path d="M 2.5371094 6 L 13.810547 16.521484 C 14.477547 17.145484 15.523453 17.145484 16.189453 16.521484 L 27.462891 6 L 2.5371094 6 z M 1 7.3007812 L 1 24 L 29 24 L 29 7.3007812 L 17.554688 17.982422 C 16.837688 18.651422 15.919 18.986328 15 18.986328 C 14.081 18.986328 13.162312 18.651422 12.445312 17.982422 L 1 7.3007812 z"></path></svg>
          {content.email}
        </a> */}
    </S.HelpButtons>  


   
    </S.PricingCards>
    );
};

export default PricingCards;

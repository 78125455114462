import styled from 'styled-components';
import media from 'styled-media-query';

export const PageWrap = styled.div`
  //  max-width: 886px;
`;

export const PageIntro = styled.p`
  color: var(--text-color);
  font-weight: 600;
  padding-bottom: var(--space);
`;

export const Section = styled.section`
  // background-color: var(--bg-light);
  // border: 1px solid var(--bg-light);
`;

export const SectionAlt = styled.section`
  background-color: var(--bg-light);
  border: 1px solid var(--bg-light);
  margin-top: 1px;
`;